import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { PaymentBypassType } from 'app/shared/enums/payment-bypass-type.enum';
import { GLAccount } from 'app/shared/models/glAccount.model';
import { PaymentPayload, PaymentPayloadFeeType } from 'app/shared/models/paymentPayload.model';
import { UmCountries } from 'app/shared/models/umCountries.model';
import { ApiService } from 'app/shared/services/api/api.service';
import { CountryService } from 'app/shared/services/country/country.service';
import { StateService } from 'app/shared/services/state-management/app-state-service';
import { PermissionsService } from 'app/shared/services/state-management/permissions.service';
import { getMonths, getPaymentYears } from 'app/shared/utils/utils';
import { amexCVVValidator } from 'app/shared/validators/amex-cvv.validator';
import { creditCardExpirationValidator } from 'app/shared/validators/credit-card-expiration.validator';
import { creditCardValidator } from 'app/shared/validators/credit-card.validator';
import { noWhiteSpaces } from 'app/shared/validators/no-white-spaces.validator';
import { phoneValidator } from 'app/shared/validators/phone.validator';
import { specialCharactersValidator } from 'app/shared/validators/special-characters.validator';
import { firstValueFrom, startWith } from 'rxjs';

@Component({
  selector: 'payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit, OnChanges {
  @Input() initialCost: number = -1;
  @Input() useCompanyAddress: any;
  @Input() showContactForm: boolean = false;
  @Input() showBankAchAccountOwner: boolean = false;
  @Input() showGlAccount: boolean = true;
  @Input() showWireIdentifier: boolean = false;
  @Input() showByPass: boolean = true;
  @Input() manualPaymentProcessingFee: number = 10;

  @Output() isPaymentFormValid = new EventEmitter<boolean>();
  @Output() paymentPayloadChange = new EventEmitter<any>();
  @Output() paymentMethodChange = new EventEmitter<string>();
  @Output() totalAmountChange = new EventEmitter<number>();

  paymentPayload: PaymentPayload = new PaymentPayload();

  isUserInternal: boolean = false;
  isUserCustomerService: boolean = false;
  showAddressForm: boolean = true;
  role: string = '';
  paymentMonths: string[];
  paymentYears: number[];
  countries: UmCountries[] = [];
  glAccounts: GLAccount[];
  paymentFormValid: boolean | undefined = false;

  paymentForm: FormGroup;

  constructor(
    private permissions: PermissionsService,
    private stateService: StateService,
    private apiService: ApiService,
    public countryService: CountryService,
    private fb: FormBuilder
  ) {
    this.paymentForm = this.fb.group({
      paymentMethodRadio: ['creditCardForm'],
      firstName: ['', [Validators.required, noWhiteSpaces]],
      lastName: ['', [Validators.required, noWhiteSpaces]],
      address1: ['', [Validators.required, noWhiteSpaces, specialCharactersValidator]],
      city: ['', noWhiteSpaces],
      email: [
        '',
        [
          Validators.email,
          Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$'),
          Validators.required,
        ],
      ],
      phone: ['', [Validators.required, phoneValidator]],
      state: ['', noWhiteSpaces],
      stateLabelText: ['Generic_StateIntlLabel'],
      postalCode: ['', [noWhiteSpaces]],
      country: ['', noWhiteSpaces],
      creditCardForm: this.fb.group({
        cardNumber: [
          '',
          [Validators.required, noWhiteSpaces, creditCardValidator],
        ],
        cardExpirationMonth: ['', [Validators.required, noWhiteSpaces]],
        cardExpirationYear: ['', [Validators.required, noWhiteSpaces]],
        cardCVV: [
          '',
          [
            Validators.required,
            Validators.pattern('[0-9]{3,4}'),
            amexCVVValidator,
          ],
        ],
      }, { validators: creditCardExpirationValidator() }),
      bankAchForm: this.fb.group({
        bankAccountNumber: ['', [Validators.required, noWhiteSpaces]],
        bankRoutingNumber: ['', [Validators.required, Validators.minLength(9)]],
        bankAccountOwner: ['', [Validators.required, noWhiteSpaces]],
        bankAccountType: ['', [Validators.required, noWhiteSpaces]],
      }),
      wireForm: this.fb.group({
        wireIdentifier: ['', [Validators.required, noWhiteSpaces]],
        glCode: ['', Validators.required],
        originatingPartyName: ['', [Validators.required, noWhiteSpaces, specialCharactersValidator]],
      }),
      checkForm: this.fb.group({
        glCode: ['', [Validators.required]],
        checkNumber: ['', [Validators.required, noWhiteSpaces]],
        address1: ['', [Validators.required]],
        totalAmount: [this.initialCost, [Validators.required, noWhiteSpaces]],
      }),
    });

    this.countryService.updateAddressRequiredFields(
      this.paymentForm as FormGroup
    );

    this.paymentForm.valueChanges.subscribe((data: any) => {
      
      
      setTimeout(() => {
        this.paymentForm
          ?.get('creditCardForm')
          ?.get('cardCVV')
          ?.updateValueAndValidity({ emitEvent: false });
      }, 0);

      const {
        paymentMethodRadio,
        creditCardForm,
        bankAchForm,
        wireForm,
        checkForm,
        ...rest
      } = data;
      
      this.paymentPayload = new PaymentPayload();
      const checkTotalAmount = this.paymentForm.get('checkForm.totalAmount')?.value;
      this.paymentPayload.totalAmount = checkTotalAmount;

      if (checkTotalAmount !== this.initialCost) {
        this.totalAmountChange.emit(checkTotalAmount); 
      }
      
      let selectedPaymentData = {};
      let paymentMethod = -1;
      let paymentBypassType = PaymentBypassType.None;

      this.paymentPayload.fees ??= [];
      if (this.paymentPayload.fees.some((x) => x.type === PaymentPayloadFeeType.ManualPayment)) {
        this.paymentPayload.fees = this.paymentPayload.fees.filter((x) => x.type !== PaymentPayloadFeeType.ManualPayment);
      }

      if (paymentMethodRadio === 'creditCardForm') {
        paymentMethod = 1;
        selectedPaymentData = { ...creditCardForm };
      } else if (paymentMethodRadio === 'bankAchForm') {
        paymentMethod = 0;
        selectedPaymentData = { ...bankAchForm };
      } else if (paymentMethodRadio === 'wireForm') {
        paymentMethod = 2;
        selectedPaymentData = { ...wireForm };
        this.paymentPayload.fees.push({ type: PaymentPayloadFeeType.ManualPayment, description: 'Payment Received by Phone/Mail Processing Fee', amount: this.manualPaymentProcessingFee });
      } else if (paymentMethodRadio === 'checkForm') {
        paymentMethod = 3;
        selectedPaymentData = { ...checkForm };
        this.paymentPayload.fees.push({ type: PaymentPayloadFeeType.ManualPayment, description: 'Payment Received by Phone/Mail Processing Fee', amount: this.manualPaymentProcessingFee });
      } else if (paymentMethodRadio === 'participantBypass') {
        paymentMethod = 4;
        paymentBypassType = PaymentBypassType.Participant;
      } else if (paymentMethodRadio === 'csBypass') {
        paymentMethod = 4;
        paymentBypassType = PaymentBypassType.CS;
      }

      this.paymentPayload = {
        ...this.paymentPayload,
        ...rest,
        ...selectedPaymentData,
        paymentBypassType,
        paymentMethod,
      };
      
      this.emitPaymentPayload();
    });
  }

  async ngOnInit() {
    if (this.stateService.role$) {
      this.role = (await firstValueFrom(this.stateService.role$)) || '';
      this.isUserInternal = this.permissions.hasInternalAccess(this.role);
      this.isUserCustomerService = this.permissions.hasCustomerSvcAccess(
        this.role
      );
    }
    this.countries = await firstValueFrom(this.apiService.getCountries());
    this.paymentMonths = getMonths();
    this.paymentYears = getPaymentYears();
    
    if (this.isUserInternal) {
      this.apiService.getGLAccounts().subscribe((x) => (this.glAccounts = x));
    }
    
    this.toggleChildPaymentForms();

    this.emitFormValidity();

    this.paymentForm.statusChanges.subscribe(() => {
      this.emitFormValidity();
    });
  }

  private emitFormValidity(): void {
    this.isPaymentFormValid.emit(this.paymentForm.valid);
  }

  private emitPaymentPayload(): void {
    this.paymentPayloadChange.emit(this.paymentPayload);
  }

  private emitMethodType(paymentMethod: string): void {
    this.paymentMethodChange.emit(paymentMethod);
  }

  toggleChildPaymentForms() {
    const paymentMethodControl = this.paymentForm.get('paymentMethodRadio');

    paymentMethodControl?.valueChanges
      .pipe(startWith('creditCardForm'))
      .subscribe((selectedOption: string) => {
        // Disable all controls first, except paymentMethodRadio radio button
        Object.keys(this.paymentForm.controls).forEach((controlName) => {
          if (controlName !== 'paymentMethodRadio') {
            this.paymentForm.get(controlName)?.disable();
          }
        });

        this.paymentForm.get('checkForm.totalAmount')?.setValue(this.initialCost, { emitEvent: false });
        this.totalAmountChange.emit(this.initialCost);

        // Helper to enable common fields
        const enableCommonFields = () => {
          this.paymentForm.get('firstName')?.enable();
          this.paymentForm.get('lastName')?.enable();
          this.paymentForm.get('address1')?.enable();
          this.paymentForm.get('city')?.enable();
          this.paymentForm.get('state')?.enable();
          this.paymentForm.get('postalCode')?.enable();
          this.paymentForm.get('country')?.enable();
          if (this.showContactForm) {
            this.paymentForm.get('email')?.enable();
            this.paymentForm.get('phone')?.enable();
          }
        };

        // Enable fields based on selected option
        if (selectedOption === 'creditCardForm') {
          enableCommonFields();
          this.paymentForm.get('creditCardForm.cardNumber')?.enable();
          this.paymentForm.get('creditCardForm.cardExpirationMonth')?.enable();
          this.paymentForm.get('creditCardForm.cardExpirationYear')?.enable();
          this.paymentForm.get('creditCardForm.cardCVV')?.enable();
        } else if (selectedOption === 'bankAchForm') {
          enableCommonFields();
          this.paymentForm.get('bankAchForm.bankAccountNumber')?.enable();
          this.paymentForm.get('bankAchForm.bankRoutingNumber')?.enable();
          this.paymentForm.get('bankAchForm.bankAccountType')?.enable();
          if (this.showBankAchAccountOwner) {
            this.paymentForm.get('bankAchForm.bankAccountOwner')?.enable();
          }
        } else if (selectedOption === 'wireForm') {
          enableCommonFields();
          if (this.showWireIdentifier) {
            this.paymentForm.get('wireForm.wireIdentifier')?.enable();
          } else {
            this.paymentForm.get('wireForm.glCode')?.enable();
            this.paymentForm.get('wireForm.originatingPartyName')?.enable();
          }
        } else if (selectedOption === 'checkForm') {
          this.paymentForm.get('firstName')?.enable();
          this.paymentForm.get('lastName')?.enable();
          if (this.showContactForm) {
            this.paymentForm.get('email')?.enable();
            this.paymentForm.get('phone')?.enable();
          }
          if (this.showGlAccount) {
            this.paymentForm.get('checkForm.glCode')?.enable();
          }
          this.paymentForm.get('checkForm.checkNumber')?.enable();
          this.paymentForm.get('checkForm.address1')?.enable();
          this.paymentForm.get('checkForm.totalAmount')?.enable();
        } else if (
          selectedOption === 'participantBypass' ||
          selectedOption === 'csBypass'
        ) {
          // No fields to enable
        }

        this.emitMethodType(this.paymentForm.get('paymentMethodRadio')?.value);
      });
  }

  copyCarrierAddressToPaymentInfoAddress(): void {
    if (this.useCompanyAddress) {
      this.paymentForm.patchValue({
        address1: this.useCompanyAddress?.address1 || '',
        city: this.useCompanyAddress?.city || '',
        state: this.useCompanyAddress?.state || '',
        postalCode: this.useCompanyAddress?.postalCode || '',
        country: this.useCompanyAddress?.countryCode?.slice(0, 3) || '',
      });
    }
    this.paymentForm.updateValueAndValidity();
  }


  getBypassType(): PaymentBypassType {
    if (this.paymentForm.get('paymentMethodRadio')?.value === 'participantBypass') {
      return PaymentBypassType.Participant;
    } else if (this.paymentForm.get('paymentMethodRadio')?.value === 'csBypass') {
      return PaymentBypassType.CS;
    }

    return PaymentBypassType.None;
  }

  cardFade(firstDigit: string): string[] {
    const cardNumber = this.paymentForm
      .get('creditCardForm')
      ?.get('cardNumber')?.value;

    if (!cardNumber || cardNumber.length <= 1) {
      return ['cc-img'];
    }

    return cardNumber.charAt(0) !== firstDigit
      ? ['cc-img', 'cc-fade']
      : ['cc-img'];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['initialCost'] && !changes['initialCost']?.firstChange) {
      this.paymentForm.get('checkForm.totalAmount')?.setValue(this.initialCost);
    }
  }
}
