import { AbstractControl, ValidatorFn } from '@angular/forms';
export function getPaymentYears(numberOfYears: number = 10): number[] {
  const currentYear = new Date().getFullYear();
  const years: number[] = [];
  for (let i = 0; i <= numberOfYears; i++) {
    years.push(currentYear + i);
  }
  return years;
}

export function getMonths(): string[] {
  return Array.from({ length: 12 }, (_, index) => String(index + 1).padStart(2, '0'));
}

export function validateNumeric<T>(input: T): Promise<boolean> {
  return new Promise((resolve, reject) => {
    let determinedValue: number | undefined;
    
    try {
      if (typeof input === 'string') {
        try {
          determinedValue = parseInt(input.toString());
        } catch {
          // leave determined value as undefined on failed parse
        }
      } else if (typeof input === 'number') {
        if (!isNaN(input as number)) {
          determinedValue = input as number;
        }
      } else {
        reject('Invalid input type');
      }

      // True if a value exists and is a number
      resolve(!!determinedValue && !isNaN(determinedValue));
    } catch {
      reject('Unknown issue validating numeric input');
    }
  });
}

export function isDate(value: any): value is Date {
  let val = new Date(value);
  return  val instanceof Date && !isNaN(val.getTime());
}



export function atLeastOneFieldRequired(fieldNames: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const atLeastOneFilled = fieldNames.some(fieldName => control.get(fieldName)?.value);
    return atLeastOneFilled ? null : { 'required': true }; // Use quotes for 'required'
  };
}