import { Component, OnInit, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { FormBuilder, FormGroup } from '@angular/forms';
import { from, Observable } from 'rxjs';
import { StateService } from '../../../shared/services/state-management/app-state-service';
import { PermissionsService } from 'app/shared/services/state-management/permissions.service';
import { LoginService } from '../../../shared/services/login/login.service';
import { ApiService } from '../../../shared/services/api/api.service';
import { Role } from 'app/shared/enums/role';
import { SessionStorageService } from 'app/shared/services/state-management/session-storage.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ViewAsUserComponent } from 'app/admin/components/view-as-user/view-as-user.component';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})

export class NavComponent implements OnInit {
  @Input() authed: boolean = false;
  @Input() currentUser: any = null;
  @Input() role: string = '';
  modalDialogViewasUser: MatDialogRef<ViewAsUserComponent, any> | undefined;
  isCollapsed = true;

  languageList: any[] = [];

  searchUserForm!: FormGroup;
  searchUserSubmitted = false;
  searchUserResult!: Observable<any>;
  typedUser?: string;
  viewUserSelected = false;
  roles: string[] = [];
  dialogConfig = new MatDialogConfig();
  requestorID = '';

  constructor(
    public authStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    public stateService: StateService,
    private router: Router,
    public translate: TranslateService,
    public permissions: PermissionsService,
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    public matDialog: MatDialog,
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    private sessionStorage: SessionStorageService,
    private api: ApiService
  ) {
    this.languageList = [
      { id: 'en', name: 'ENG' },
      { id: 'fr', name: 'FRE' },
      { id: 'es', name: 'SPA' }];
    translate.addLangs(['en', 'fr', 'es']);
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.requestorID = this.stateService.getCurrentUser()?.requestorID;
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  async login(url?: string) {
    await this.oktaAuth.signInWithRedirect({ originalUri: url === null ? '/home' : url });
  }

  async logout() {
    await this.oktaAuth.signOut();
    this.stateService.clearSession();
    await this.oktaAuth.closeSession();
    await this.oktaAuth.revokeAccessToken();
    this.router.navigate(["/"]);
  }

  redirectToApplyPage() {
    this.sessionStorage.removeItem("applyAsRequestorID");
    this.router.navigate(['/apply-new-scac']);
  }

  resumeLoggedInUser() {
    this.authStateService.authState$.subscribe(isAuthed => {
      if (isAuthed.isAuthenticated) {
        this.oktaAuth.getUser().then(oktaUser => {
          this.api.getUserByEmail(oktaUser.email as string).subscribe((user) => {
            this.stateService.setRoleDirect(from([user.roles![0]]) as Observable<string>);
            this.stateService.setAuth(from([true]) as Observable<boolean>);
            this.currentUser = user;
            this.role = user.roles![0];
            this.stateService.setCurrentUser(user);
            this.stateService.clearActingUser();
          });
        });
      }
    });
  }

  navigateUser() {
    if (this.role === Role.Reseller) {
      this.router.navigate(['/admin/profile/' + this.requestorID])
    } else if (this.permissions.hasManageAccountsHome(this.role)) {
      this.router.navigate(['/admin/manage-accounts']).then(() => {
        location.reload();
    });
    } else if (this.role === Role.Carrier || this.permissions.hasPartnerCodeAccess(this.role) || this.role === Role.Border) {
      this.router.navigate(['/admin/profile/', this.currentUser.requestorID]).then(() => {
        location.reload();
    });
    } else {
      this.router.navigate(['']);
    }
  }

  openNewTab(url: string){
    window.open(url, "_blank");
  }
}
