// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  configName: 'default',
  apiBaseURL: 'https://dsanpt.nmfta.org/',
  funcBaseUrl: 'https://dscquy.nmfta.org/api/',
  carrierBaseURL: 'https://dsanpt.nmfta.org/carrierprofile', 
  requestorContactBaseURL: 'https://dsanpt.nmfta.org/requestorContact',
  applicationBaseURL: 'https://dsanpt.nmfta.org/application',
  accountBaseURL: 'https://dsanpt.nmfta.org/requestor',
  // requestorContact: 'https://dsanpt.nmfta.org/requestorContact',
  
  //requestorContact: 'https://localhost:44359/requestorContact', //local
  //  accountBaseURL: 'http://localhost:7081/api',
  emailBaseUrl: 'https://dsanpt.nmfta.org/emailer',
  profileBaseUrl: 'https://dsanpt.nmfta.org/carrierprofile',
  dataProductsBaseUrl: 'https://dsanpt.nmfta.org/products',
  subscriptionBaseUrl: 'https://dsanpt.nmfta.org/dataproducts',
  financeBaseUrl: 'https://dsanpt.nmfta.org/finance',
  notesBaseUrl: 'https://dsanpt.nmfta.org/notes',
  reservationsBaseUrl: 'https://dsanpt.nmfta.org/reservations',
  appPageTitle: 'NMFTA - National Motor Traffic Association, Inc.',
  oktaIssuer: 'https://partners.nmfta.org/oauth2/default',
  oktaClientId: '0oa2ujbuz7WFCvs3n697',
  googleAnalyicsTrackId: 'G-B762JL355R',
  appTitle: 'DEV - NMFTA SCAC®'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
