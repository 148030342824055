//Angular inputs
import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function noWhiteSpaces(control: AbstractControl): ValidationErrors | null {
  if (control.value === '') {
    return null;
  }

  const regex = /^(?!\s*$).*/;
  return regex.test(control.value) ? null : { noWhiteSpaces: true };
}
