<form [formGroup]="paymentForm" class="w-100 mb-5">
  <mat-radio-group class="radio-buttons" formControlName="paymentMethodRadio">
    <!-- Credit Card Radio Button Option -->
    <div class="row mt-4">
      <div class="w-auto">
        <mat-radio-button [value]="'creditCardForm'" name="paymentMethodRadio">
          {{ "Apply_CreditCard_payment" | translate }}
        </mat-radio-button>
      </div>
      <div class="col w-100">
        <img 
          src="assets/cc_visa.png" 
          [ngClass]="cardFade('4')" />
        <img
          src="assets/cc_mastercard.png"
          [ngClass]="cardFade('5')"
        />
        <img
          src="assets/cc_americanexpress.png"
          [ngClass]="cardFade('3')"
        />
        <img
          src="assets/cc_discover.png"
          [ngClass]="cardFade('6')"
        />
      </div>
    </div>

    <!-- Credit Card Form -->
    <ng-container
      *ngIf="paymentForm.get('paymentMethodRadio')?.value === 'creditCardForm'"
      formGroupName="creditCardForm"
    >
      <div class="row">
        <!-- Card Number -->
        <div class="mt-2 col-12 col-lg-4 col-md-6">
          <input-wrapper [label]="'Generic_CardNumber'">
            <input
              class="form-control"
              name="cardNumber"
              id="cardNumber"
              mask="00?99 9999 9999 9999"
              [placeholder]="'Apply_CreditCard' | translate"
              formControlName="cardNumber"
            />
          </input-wrapper>
        </div>

        <!-- Expiry month -->
        <div class="mt-2 col-6 col-lg-3 col-md-6">
          <input-wrapper [label]="'Generic_ExpiryMonth'">
            <mat-select
              class="form-control"
              name="cardExpirationMonth"
              id="cardExpirationMonth"
              formControlName="cardExpirationMonth"
            >
              @for (month of paymentMonths; track $index) {
              <mat-option [value]="month">{{ month }}</mat-option>
              }
            </mat-select>
          </input-wrapper>
        </div>

        <!-- Expiry year -->
        <div class="mt-2 col-6 col-lg-3 col-md-6">
          <input-wrapper [label]="'Generic_ExpiryYear'">
            <mat-select
              class="form-control"
              name="cardExpirationYear"
              id="cardExpirationYear"
              formControlName="cardExpirationYear"
            >
              @for (year of paymentYears; track $index ) {
                <mat-option [value]="year">{{ year }}</mat-option>
              }
            </mat-select>
          </input-wrapper>
        </div>

        <!-- Security Code -->
        <div class="mt-2 col-12 col-lg-2 col-md-6">
          <input-wrapper [label]="'Generic_SecurityCode'">
            <input
              class="form-control"
              type="text"
              uppercase
              name="cardCVV"
              id="cardCVV"
              [placeholder]="'Generic_SecurityCode' | translate"
              formControlName="cardCVV"
              mask="00?99"
            />
          </input-wrapper>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="nameForm"></ng-container>
      <ng-container *ngTemplateOutlet="contactForm"></ng-container>
      <ng-container *ngTemplateOutlet="addressForm"></ng-container>
    </ng-container>

    <hr class="mt-4" />

    <!-- Banck ACH Radio Button Option -->
    <div class="row mt-4">
      <div class="col-12">
        <mat-radio-button [value]="'bankAchForm'" name="paymentMethodRadio">
          {{ "Apply_BankACH_payment" | translate }}
        </mat-radio-button>
      </div>
    </div>

    <!-- Bank ACH Form -->
    <ng-container
      *ngIf="paymentForm.get('paymentMethodRadio')?.value === 'bankAchForm'"
      formGroupName="bankAchForm"
    >
      <div class="mt-2 row">
        <div class="col-12">
          <p>{{ "Generic_AchUsOnly" | translate }}</p>
          <p>{{ "Generic_AchProcessingTime" | translate }}</p>
        </div>
      </div>

      <div class="row mt-2">
        <!-- Bank Account -->
        <div 
          class="mt-2 col-12 col-sm-6" 
          [ngClass]="showBankAchAccountOwner ? 'col-lg-3' : 'col-md-4'" 
        >
          <input-wrapper [label]="'Generic_BankAccount'">
            <input
              class="form-control"
              type="text"
              uppercase
              name="bankAccountNumber"
              id="bankAccountNumber"
              [placeholder]="'Generic_BankAccount' | translate"
              formControlName="bankAccountNumber"
            />
          </input-wrapper>
        </div>

        <!-- Bank Routing Number -->
        <div 
          class="mt-2 col-12 col-sm-6"
          [ngClass]="showBankAchAccountOwner ? 'col-lg-3' : 'col-md-4'" 
        >
          <input-wrapper [label]="'Generic_BankRouting'">
            <input
              class="form-control"
              type="text"
              id="bankRoutingNumber"
              name="bankRoutingNumber"
              formControlName="bankRoutingNumber"
              maxlength="9"
              mask="000000000"
              [placeholder]="'Generic_BankRouting' | translate"
            />
          </input-wrapper>
        </div>

        <!-- Account Type -->
        <div 
          class="mt-2 col-12"
          [ngClass]="
            showBankAchAccountOwner ? 
            ['col-sm-6', 'col-lg-3'] : 
            ['col-sm-12', 'col-md-4']
          " 
        >
          <input-wrapper [label]="'Generic_BankAccountType'">
            <mat-select
              class="form-control"
              name="bankAccountType"
              id="bankAccountType"
              formControlName="bankAccountType"
            >
              <mat-option value="C">
                {{ "Generic_BankChecking" | translate }}
              </mat-option>
              <mat-option value="S">
                {{ "Generic_BankSavings" | translate }}
              </mat-option>
            </mat-select>
          </input-wrapper>
        </div>

        <!-- Account Owner -->
        <div *ngIf="showBankAchAccountOwner" class="mt-2 col-12 col-sm-6 col-lg-3">
          <input-wrapper [label]="'Generic_BankAccountOwner'">
            <mat-select
              class="form-control"
              name="bankAccountOwner"
              id="bankAccountOwner"
              formControlName="bankAccountOwner"
            >
              <mat-option value="P">
                {{ "Generic_BankPersonal" | translate }}
              </mat-option>
              <mat-option value="C">
                {{ "Generic_BankCorporate" | translate }}
              </mat-option>
            </mat-select>
          </input-wrapper>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="nameForm"></ng-container>
      <ng-container *ngTemplateOutlet="contactForm"></ng-container>
      <ng-container *ngTemplateOutlet="addressForm"></ng-container>
    </ng-container>

    <hr class="mt-4" *ngIf="isUserInternal" />

    <!-- ccBank Or Wire Radio Button Option -->
    <div *ngIf="isUserInternal" class="row mt-4">
      <div class="col-12">
        <mat-radio-button [value]="'wireForm'" name="paymentMethodRadio">
          {{ "Apply_Wire_payment" | translate }}
        </mat-radio-button>
      </div>
    </div>

    <!-- ccBank Or Wire form -->
    <ng-container
      *ngIf="
        paymentForm.get('paymentMethodRadio')?.value === 'wireForm' &&
        isUserInternal
      "
      formGroupName="wireForm"
    >
      @if (showWireIdentifier) {
        <div class="row mt-2">
          <!-- Wire Identifier -->
          <div class="mt-2 col-12">
            <input-wrapper [label]="'Generic_WireId'">
              <input
                type="text"
                uppercase
                class="form-control"
                name="wireIdentifier"
                id="wireIdentifier"
                [placeholder]="'Generic_WireId' | translate"
                formControlName="wireIdentifier"
              />
            </input-wrapper>
          </div>
        </div>
      } @else {
        <div class="row mt-2">
          <!-- GL Account -->
          <div class="mt-2 col-12 col-sm-6">
            <input-wrapper [label]="'Generic_GLAccount'">
              <mat-select
                class="form-control"
                name="glCode"
                id="glCode"
                formControlName="glCode"
              >
                @for (glAccount of glAccounts; track $index) {
                <mat-option [value]="glAccount.title">{{
                  glAccount.title
                }}</mat-option>
                }
              </mat-select>
            </input-wrapper>
          </div>
  
          <!-- Originating Party Name -->
          <div class="mt-2 col-12 col-sm-6">
            <input-wrapper [label]="'Generic_Originating_Party_Name'">
              <input
                type="text"
                uppercase
                class="form-control"
                name="originatingPartyName"
                id="originatingPartyName"
                [placeholder]="'Generic_Originating_Party_Name' | translate"
                formControlName="originatingPartyName"
              />
            </input-wrapper>
          </div>
        </div>
      }

      <ng-container *ngTemplateOutlet="nameForm"></ng-container>
      <ng-container *ngTemplateOutlet="contactForm"></ng-container>
      <ng-container *ngTemplateOutlet="addressForm"></ng-container>
    </ng-container>

    <hr class="mt-4" *ngIf="isUserInternal" />

    <!-- Check Radio Button Option -->
    <div *ngIf="isUserInternal" class="row mt-4">
      <div class="col-12">
        <mat-radio-button [value]="'checkForm'" name="paymentMethodRadio">
          {{ "Apply_CheckNotProcessed_payment" | translate }}
        </mat-radio-button>
      </div>
    </div>

    <!-- Check form -->
    <ng-container
      *ngIf="
        paymentForm.get('paymentMethodRadio')?.value === 'checkForm' &&
        isUserInternal
      "
      formGroupName="checkForm"
    >
      <div class="row mt-2">
        <!-- Check Number -->
        <div 
          class="mt-2 col-12"
          [ngClass]="showGlAccount ? ['col-sm-6', 'col-md-6', 'col-lg-5'] : ['col-sm-12', 'col-md-4']"
        >
          <input-wrapper [label]="'Generic_CheckNumber'">
            <input
              type="text"
              uppercase
              class="form-control"
              name="checkNumber"
              id="checkNumber"
              [placeholder]="'Generic_CheckNumber' | translate"
              formControlName="checkNumber"
            />
          </input-wrapper>
        </div>

        <!-- GL Account -->
        <div *ngIf="showGlAccount" class="mt-2 col-12 col-sm-6 col-lg-2">
          <input-wrapper [label]="'Generic_GLAccount'">
            <mat-select
              class="form-control"
              name="glCode"
              id="glCode"
              formControlName="glCode"
            >
              @for (glAccount of glAccounts; track $index) {
              <mat-option [value]="glAccount.title">{{
                glAccount.title
              }}</mat-option>
              }
            </mat-select>
          </input-wrapper>
        </div>

        <!-- Payment Date -->
        <div 
          class="mt-2 col-12 col-sm-6"
          [ngClass]="showGlAccount ? 'col-lg-3' : 'col-md-4'"
        >
          <input-wrapper [label]="'Generic_PaymentDate'">
            <input
              type="date"
              class="form-control"
              name="address1"
              id="address1"
              formControlName="address1"
              maxlength="200"
            />
          </input-wrapper>
        </div>

        <!-- Check Amount -->
        <div 
          class="mt-2 col-12 col-sm-6"
          [ngClass]="showGlAccount ? 'col-lg-2' : 'col-md-4'"
        >
          <input-wrapper [label]="'Generic_CheckAmount'">
            <input
              type="number"
              class="form-control"
              name="totalAmount"
              id="totalAmount"
              min="0"
              [placeholder]="'Generic_CheckAmount' | translate"
              formControlName="totalAmount"
            />
          </input-wrapper>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="nameForm"></ng-container>
      <ng-container *ngTemplateOutlet="contactForm"></ng-container>
    </ng-container>

    <ng-container *ngIf="showByPass">
      <!-- Participant Bypass -->
      <hr class="mt-4" *ngIf="isUserCustomerService" />
      <!-- Participant Bypass -->
      <div *ngIf="isUserCustomerService" class="row mt-4">
        <div class="col-12">
          <mat-radio-button [value]="'participantBypass'" name="paymentMethodRadio">
            {{ "Apply_ParticipantBypassCS" | translate }}
          </mat-radio-button>
        </div>
      </div>
  
      <!-- CS Bypass -->
      <hr class="mt-4" *ngIf="isUserCustomerService" />
      <!-- CS Bypass -->
      <div *ngIf="isUserCustomerService" class="row mt-4">
        <div class="col-12">
          <mat-radio-button [value]="'csBypass'" name="paymentMethodRadio">
            {{ "Apply_CSBypass" | translate }}
          </mat-radio-button>
        </div>
      </div>      
    </ng-container>

    <hr class="mt-4" />

    <ng-template #addressForm>
      <!-- Address Form -->
      <div class="row mt-0 mb-4">
        <!-- Country -->
        <div class="mt-2 col-12 col-lg-3 col-sm-7">
          <input-wrapper [label]="'Generic_Country'">
            <mat-select
              class="form-control"
              name="country"
              id="country"
              formControlName="country"
            >
              @for (country of countries; track $index) {
              <mat-option [value]="country.umCountryCode">{{
                country.umCountryName
              }}</mat-option>
              }
            </mat-select>
          </input-wrapper>
        </div>

        <!-- Postal Code -->
        <div class="mt-2 col-12 col-lg-3 col-sm-5">
          <input-wrapper [label]="'Generic_PostalCode'">
            <input
              class="form-control"
              type="text"
              uppercase
              name="postalCode"
              id="postalCode"
              [placeholder]="'Generic_PostalCode' | translate"
              formControlName="postalCode"
              maxlength="10"
            />
          </input-wrapper>
        </div>

        <!-- City -->
        <div class="mt-2 col-12 col-lg-3 col-sm-7">
          <input-wrapper [label]="'Generic_City'">
            <input
              class="form-control"
              type="text"
              uppercase
              name="city"
              id="city"
              [placeholder]="'Generic_City' | translate"
              formControlName="city"
              maxlength="50"
            />
          </input-wrapper>
        </div>

        <!-- State / Province -->
        <div class="mt-2 col-12 col-lg-3 col-sm-5">
          <input-wrapper
            [label]="this.paymentForm.get('stateLabelText')?.value"
          >
            <input
              class="form-control"
              type="text"
              uppercase
              name="state"
              id="state"
              [attr.maxLength]="countryService.isAddressRequired ? 2 : null"
              placeholder="{{ this.paymentForm.get('stateLabelText')?.value | translate }}"
              formControlName="state"
            />
          </input-wrapper>
        </div>

        <!-- Street Address -->
        <div class="mt-2 col-12">
          <input-wrapper [label]="'Generic_AddressLine'">
            <input
              class="form-control"
              type="text"
              name="address1"
              id="address1"
              uppercase
              [placeholder]="'Generic_AddressLine' | translate"
              formControlName="address1"
              maxlength="200"
            />
          </input-wrapper>
        </div>

        <!-- Use Company Address Button -->
        <div 
          class="col d-flex flex-column align-items-end mt-4"
          *ngIf="useCompanyAddress"
        >
          <button
            class="btn text-bg-nmftaprimary me-1 rounded-pill small"
            type="button"
            (click)="copyCarrierAddressToPaymentInfoAddress()"
          >
            {{ "Generic_UseCompanyAddress" | translate }}
          </button>
        </div>
      </div>
    </ng-template>

    <ng-template #nameForm>
      <div class="row">
        <!-- Card Holder / Name on Account First Name -->
        <div class="mt-2 col-12 col-md-6">
          <input-wrapper
            [label]="
              paymentForm.get('paymentMethodRadio')?.value === 'creditCardForm'
                ? 'Generic_CardName'
                : 'Generic_BankAccountHolder'
            "
          >
            <input
              class="form-control"
              type="text"
              uppercase
              name="firstName"
              id="firstName"
              [placeholder]="'Generic_FirstName' | translate"
              formControlName="firstName"
              maxlength="99"
            />
          </input-wrapper>
        </div>

        <!-- Card Holder / Name on Account Last Name -->
        <div class="mt-2 col-12 col-md-6">
          <input-wrapper>
            <input
              class="form-control"
              type="text"
              uppercase
              name="lastName"
              id="lastName"
              [placeholder]="'Generic_LastName' | translate"
              formControlName="lastName"
              maxlength="99"
            />
          </input-wrapper>
        </div>
      </div>
    </ng-template>

    <ng-template #contactForm>
      <div *ngIf="showContactForm" class="row">
        <!-- E-mail -->
        <div class="mt-2 col-12 col-md-6">
          <input-wrapper [label]="'Generic_EmailAddress'">
            <input
              class="form-control"
              type="email"
              uppercase
              name="email"
              id="email"
              [placeholder]="'Generic_EmailAddress' | translate"
              formControlName="email"
              maxlength="100"
            />
          </input-wrapper>
        </div>

        <!-- Phone -->
        <div class="mt-2 col-12 col-md-6">
          <input-wrapper [label]="'Generic_Phone'">
            <input
              class="form-control"
              type="text"
              name="phone"
              id="phone"
              [placeholder]="'Generic_Phone' | translate"
              formControlName="phone"
              maxlength="99"
            />
          </input-wrapper>
        </div>
      </div>
    </ng-template>
  </mat-radio-group>
</form>
